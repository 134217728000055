<template>
  <page-view>
    <div class="notice_wrapper">
      <a-button @click="handleExtensionInsert" class="add_btn" type="primary"
        >新增</a-button
      >

      <div class="notice_table">
        <a-table
          ref="table"
          rowKey="id"
          :columns="columns"
          :data-source="dataSource"
        >
          <span slot="serial" slot-scope="text, record, index">{{
            index + 1
          }}</span>

          <template slot="position" slot-scope="text, scope">
            {{ text == "TOP" ? "顶部" : "右边" }}
          </template>
          <template slot="enable" slot-scope="text, record">
            <a-switch :checked="text" @change="handleEnable(record)" />
          </template>
          <template slot="operation" slot-scope="text, scope">
            <span @click="updateExtension(scope)" class="delete_btn">修改</span>
            <span @click="deleteExtension(scope)" class="delete_btn">删除</span>
          </template>
        </a-table>
      </div>
      <a-modal
        title="新增广告"
        :width="780"
        :visible="showEditModal"
        @cancel="handleCancel"
        @ok="handleOk"
      >
        <a-form :hideRequiredMark="true">
          <a-form-item
            label="广告图"
            :labelCol="{
              lg: { span: 3 },
              sm: { span: 6 },
              md: { span: 6 }
            }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-upload
              :multiple="false"
              :showUploadList="false"
              list-type="picture"
              :customRequest="customRequest"
              accept="image/gif, image/png, image/jpeg, image/webp"
            >
              <div
                v-if="preview.image"
                style="position:relative"
                @mouseenter="
                  () => {
                    editTip = true;
                  }
                "
                @mouseleave="
                  () => {
                    editTip = false;
                  }
                "
              >
                <img :src="preview.image" class="priview_image" />
                <div v-if="editTip" class="edit_tip">重新上传</div>
              </div>
              <div class="picture_upload" v-else>
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item
            label="贴牌ID"
            :labelCol="{
              lg: { span: 3 },
              sm: { span: 6 },
              md: { span: 6 }
            }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 16 } }"
          >
            <a-textarea
              v-model.trim="addForm.oem"
              placeholder="填写贴牌uid，回车键换行，一行一个~"
              style="resize: none; height: 120px"
              :autoSize="{ minRows: 5 }"
            />
          </a-form-item>
          <a-form-item
            label="跳转链接"
            :labelCol="{
              lg: { span: 3 },
              sm: { span: 6 },
              md: { span: 6 }
            }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input v-model="addForm.link" placeholder="填写跳转链接" />
          </a-form-item>
          <a-form-item
            label="是否开启"
            :labelCol="{
              lg: { span: 3 },
              sm: { span: 6 },
              md: { span: 6 }
            }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-switch default-checked v-model="addForm.enable" />
          </a-form-item>
          <a-form-item
            label="广告位置"
            :labelCol="{
              lg: { span: 3 },
              sm: { span: 6 },
              md: { span: 6 }
            }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-radio-group v-model="addForm.position" class="trag_group">
              <a-radio class="radioStyle" value="TOP" :key="0">顶部</a-radio>
              <a-radio class="radioStyle" value="RIGHT" :key="1">右边</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </page-view>
</template>

<script>
import { PageView } from "@/layouts";
import {
  filePreUpload,
  uploadOSS,
  extensionListGetAdmin,
  extensionInsert,
  extensionUpdate,
  extensionDelete
} from "@api";
import { uuid } from "@/utils/util";
import { jsonToArray, noticeType, formatDate } from "@/utils/util";
export default {
  components: {
    PageView
  },

  data() {
    return {
      showEditModal: false,
      columns: [
        {
          title: "序号",
          scopedSlots: { customRender: "serial" }
        },
        {
          title: "位置",
          dataIndex: "position",
          scopedSlots: { customRender: "position" }
        },

        {
          title: "状态",
          dataIndex: "enable",
          scopedSlots: { customRender: "enable" }
        },
        {
          title: "跳转链接",
          dataIndex: "link"
        },
        {
          title: "修改时间",
          dataIndex: "updateTime",
          customRender: formatDate
        },
        {
          title: "操作",
          dataIndex: "id",
          scopedSlots: { customRender: "operation" }
        }
      ],
      preview: { image: "", show: false },
      editTip: false,
      addForm: {
        oem: "",
        enable: true,
        link: "",
        position: "TOP"
      },
      editTag: 0,
      dataSource: []
    };
  },
  mounted() {
    this.handleExtensionListGet();
  },
  methods: {
    handleExtensionListGet() {
      extensionListGetAdmin().then(res => {
        this.dataSource = res.data;
      });
    },
    handleExtensionInsert() {
      this.notice = {};
      this.addForm = {
        oem: "",
        enable: true,
        link: "",
        position: "TOP"
      };
      this.editTag = 0;
      this.preview.image = "";
      this.showEditModal = true;
    },
    customRequest(fileData) {
      filePreUpload()
        .then(response => {
          const data = response.data;
          const url = data.url;
          const prefix = data.key;
          delete data.url;
          delete data.key;
          const array = fileData.file.name.replace(" ", "").split("."); // 把文件名数组化
          let suffix = ""; // 后缀
          if (array.length > 1) {
            suffix = `.${array.pop().toLowerCase()}`;
          }
          const formData = new FormData();
          Object.keys(data).forEach(it => {
            formData.append(it, data[it]);
          });
          const key = `${prefix}/${uuid()}${suffix}`; // 图片访问路径
          formData.append("key", key);
          formData.append("file", fileData.file);
          uploadOSS(url, formData)
            .then(response => {
              // 上传成功，设置图片访问路径
              this.preview.image = "http://cache.wodh.cn/" + key;
              this.addForm.picture = "http://cache.wodh.cn/" + key;
              this.$message.success("上传成功.");
              return fileData.onSuccess(); // 上传成功了
            })
            .catch(error => {
              this.$message.error("上传异常." + error);
              return fileData.onError(); // 拒绝上传操作
            });
        })
        .catch(error => {
          this.$message.error("授权异常." + error);
          return fileData.onError(); // 拒绝上传操作
        });
    },

    deleteExtension(data) {
      this.$confirm({
        title: "是否删除该广告？",
        cancelText: "取消",
        okText: "确定",
        onOk: () => {
          //删除
          extensionDelete(data.id).then(res => {
            if (res.success) {
              this.$message.success("删除成功");
              this.handleExtensionListGet();
            } else {
              this.$message.error(res.msg);
            }
          });
        },
        onCancel() {}
      });
    },
    updateExtension(data) {
      this.editTag = 1;
      this.showEditModal = true;
      this.addForm = JSON.parse(JSON.stringify(data));
      this.preview.image = this.addForm.picture;
      if (data.oem) {
        this.addForm.oem = JSON.parse(data.oem);
        let str = "";
        this.addForm.oem.forEach((item, index) => {
          if (index == data.length - 1) {
            str += item;
          } else {
            str += item + "\n";
          }
        });
        this.addForm.oem = str;
      }
    },
    handleEnable(data) {
      data.enable = !data.enable;
      extensionUpdate(data).then(res => {
        if (res.success) {
          this.$message.success("修改成功");
          this.handleExtensionListGet();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCancel() {
      this.showEditModal = false;
    },
    handleOk() {
      const data = JSON.parse(JSON.stringify(this.addForm));
      if (this.addForm.oem) {
        data.oem = JSON.stringify(data.oem.split("\n"));
      } else {
        data.oem = "[]";
      }

      if (this.editTag == 0) {
        extensionInsert(data).then(res => {
          this.addForm = {};
          if (res.success) {
            this.$message.success("新增成功");
            this.handleExtensionListGet();
            this.showEditModal = false;
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        extensionUpdate(data).then(res => {
          if (res.success) {
            this.$message.success("修改成功");
            this.handleExtensionListGet();
            this.showEditModal = false;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.notice_wrapper {
  background-color: #fff;
  padding: 24px;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  .query_btn {
    margin-left: 64px;
  }
  .add_btn {
    margin-left: 12px;
  }

  .delete_btn {
    cursor: pointer;
    color: #1890ff;
    margin-right: 8px;
  }
  .notice_table {
    margin-top: 24px;
  }
}
.picture_upload {
  cursor: pointer;
  width: 104px;
  height: 104px;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ant-upload-text {
    margin-top: 12px;
    color: #1890ff;
  }
}
.priview_image {
  width: 104px;
  height: 104px;
}
.edit_tip {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 104px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background-color: #595959;
}
</style>
