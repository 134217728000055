var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-view',[_c('div',{staticClass:"notice_wrapper"},[_c('a-button',{staticClass:"add_btn",attrs:{"type":"primary"},on:{"click":_vm.handleExtensionInsert}},[_vm._v("新增")]),_c('div',{staticClass:"notice_table"},[_c('a-table',{ref:"table",attrs:{"rowKey":"id","columns":_vm.columns,"data-source":_vm.dataSource},scopedSlots:_vm._u([{key:"serial",fn:function(text, record, index){return _c('span',{},[_vm._v(_vm._s(index + 1))])}},{key:"position",fn:function(text, scope){return [_vm._v(" "+_vm._s(text == "TOP" ? "顶部" : "右边")+" ")]}},{key:"enable",fn:function(text, record){return [_c('a-switch',{attrs:{"checked":text},on:{"change":function($event){return _vm.handleEnable(record)}}})]}},{key:"operation",fn:function(text, scope){return [_c('span',{staticClass:"delete_btn",on:{"click":function($event){return _vm.updateExtension(scope)}}},[_vm._v("修改")]),_c('span',{staticClass:"delete_btn",on:{"click":function($event){return _vm.deleteExtension(scope)}}},[_vm._v("删除")])]}}])})],1),_c('a-modal',{attrs:{"title":"新增广告","width":780,"visible":_vm.showEditModal},on:{"cancel":_vm.handleCancel,"ok":_vm.handleOk}},[_c('a-form',{attrs:{"hideRequiredMark":true}},[_c('a-form-item',{attrs:{"label":"广告图","labelCol":{
            lg: { span: 3 },
            sm: { span: 6 },
            md: { span: 6 }
          },"wrapperCol":{ lg: { span: 8 }, sm: { span: 8 } }}},[_c('a-upload',{attrs:{"multiple":false,"showUploadList":false,"list-type":"picture","customRequest":_vm.customRequest,"accept":"image/gif, image/png, image/jpeg, image/webp"}},[(_vm.preview.image)?_c('div',{staticStyle:{"position":"relative"},on:{"mouseenter":function () {
                  _vm.editTip = true;
                },"mouseleave":function () {
                  _vm.editTip = false;
                }}},[_c('img',{staticClass:"priview_image",attrs:{"src":_vm.preview.image}}),(_vm.editTip)?_c('div',{staticClass:"edit_tip"},[_vm._v("重新上传")]):_vm._e()]):_c('div',{staticClass:"picture_upload"},[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1)])],1),_c('a-form-item',{attrs:{"label":"贴牌ID","labelCol":{
            lg: { span: 3 },
            sm: { span: 6 },
            md: { span: 6 }
          },"wrapperCol":{ lg: { span: 10 }, sm: { span: 16 } }}},[_c('a-textarea',{staticStyle:{"resize":"none","height":"120px"},attrs:{"placeholder":"填写贴牌uid，回车键换行，一行一个~","autoSize":{ minRows: 5 }},model:{value:(_vm.addForm.oem),callback:function ($$v) {_vm.$set(_vm.addForm, "oem", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"addForm.oem"}})],1),_c('a-form-item',{attrs:{"label":"跳转链接","labelCol":{
            lg: { span: 3 },
            sm: { span: 6 },
            md: { span: 6 }
          },"wrapperCol":{ lg: { span: 8 }, sm: { span: 8 } }}},[_c('a-input',{attrs:{"placeholder":"填写跳转链接"},model:{value:(_vm.addForm.link),callback:function ($$v) {_vm.$set(_vm.addForm, "link", $$v)},expression:"addForm.link"}})],1),_c('a-form-item',{attrs:{"label":"是否开启","labelCol":{
            lg: { span: 3 },
            sm: { span: 6 },
            md: { span: 6 }
          },"wrapperCol":{ lg: { span: 8 }, sm: { span: 8 } }}},[_c('a-switch',{attrs:{"default-checked":""},model:{value:(_vm.addForm.enable),callback:function ($$v) {_vm.$set(_vm.addForm, "enable", $$v)},expression:"addForm.enable"}})],1),_c('a-form-item',{attrs:{"label":"广告位置","labelCol":{
            lg: { span: 3 },
            sm: { span: 6 },
            md: { span: 6 }
          },"wrapperCol":{ lg: { span: 8 }, sm: { span: 8 } }}},[_c('a-radio-group',{staticClass:"trag_group",model:{value:(_vm.addForm.position),callback:function ($$v) {_vm.$set(_vm.addForm, "position", $$v)},expression:"addForm.position"}},[_c('a-radio',{key:0,staticClass:"radioStyle",attrs:{"value":"TOP"}},[_vm._v("顶部")]),_c('a-radio',{key:1,staticClass:"radioStyle",attrs:{"value":"RIGHT"}},[_vm._v("右边")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }